<template>
  <FormElement
    :rules="props.rules"
    :input-value="props.modelValue"
    :hide-details="props.hideDetails"
  >
    <Datepicker
      ref="datePicker"
      :model-value="props.modelValue"
      :time-picker="props.isTimePicker"
      :is-24="!props.isTimePicker"
      :enable-time-picker="props.isTimePicker"
      :min-date="minDate"
      model-type="format"
      :format="props.isTimePicker ? 'hh:mm a' : 'MM/dd/yy'"
      teleport
      @update:model-value="onDateInput"
    >
      <template #trigger>
        <BaseInput
          :label="props.label"
          :label-for-id="parsedId"
          :disabled="disabled"
        >
          <input
            v-bind="$attrs"
            :id="parsedId"
            :value="props.modelValue"
            :disabled="disabled"
            type="text"
            readonly
          />
          <template #append-inner>
            <Icon
              v-if="!isCloseIconDisplayed"
              :icon="props.isTimePicker ? ClockSvg : CalendarSvg"
              @mouseenter="isHovered = true"
              @mouseleave="isHovered = false"
            />
            <Btn
              v-else
              :size="BtnSize.SM"
              icon
              @mouseenter="isHovered = true"
              @mouseleave="isHovered = false"
              @click="clearValue"
            >
              <icon :icon="CloseSvg" :size="IconSize.SM" />
            </Btn>
          </template>
        </BaseInput>
      </template>
      <template #action-select>
        <Btn @click="selectDate">Select</Btn>
      </template>
    </Datepicker>
  </FormElement>
</template>

<script setup lang="ts">
import { computed, ref } from '#imports';
import { getUid } from '~/utils/common';
import { FormElement } from '~/components/inputs/_components/form-element';
import { BaseInput } from '~/components/inputs/_components/base-input';
import CalendarSvg from '@/assets/icons/calendar.svg?component';
import ClockSvg from '@/assets/icons/clock.svg?component';
import CloseSvg from '@/assets/icons/close.svg?component';
import { Icon, IconSize } from '~/components/icon';
import { Btn, BtnSize } from '~/components/btn';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

type Props = {
  modelValue: string;
  id?: string;
  label?: string;
  hideDetails?: boolean;
  rules?: [];
  clearable?: boolean;
  disabled?: boolean;
  isTimePicker?: boolean;
  minDate?: Date | string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();
const isHovered = ref<boolean>(false);

const parsedId = computed<string>(() => {
  return props.id ?? `input-${getUid()}`;
});

const datePicker = ref();
const selectDate = () => {
  datePicker.value.selectDate();
};

const onDateInput = (value: string) => {
  if (value) {
    emit('update:modelValue', value);
  }
};

const clearValue = () => {
  emit('update:modelValue', null);
};

const isCloseIconDisplayed = computed<boolean>(() => {
  return Boolean(props.clearable && isHovered.value && props.modelValue);
});
</script>
